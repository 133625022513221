import { createContext, useState, useContext } from 'react';
import axios from '../config/axios';
import { ReRenderContext } from '../context/ReRenderContext';
const ProductContext = createContext();

function ProductContextProvider({ children }) {
  const [price, setPrice] = useState('');
  const [searchByProductContext, setSearchByProductContext] = useState('');
  const [searchTermProductContext, setSearchTermProductContext] = useState('');
  const [isClickedOnCart, setIsClickedOnCart] = useState(false);
  const { setReRender } = useContext(ReRenderContext);
  const handleDeleteProduct = async (productId) => {
    try {
      await axios.delete(`/admin_products/del/${productId}`);
      setReRender((reRender) => !reRender);
    } catch (error) {
      console.log(error);
    }
  };

  const handleUpdateProduct = async (productId, price) => {
    try {
      await axios.patch(`/admin_products/update/${productId}`, {
        price: price,
      });
      setReRender((reRender) => !reRender);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <ProductContext.Provider
      value={{
        price,
        setPrice,
        handleDeleteProduct,
        handleUpdateProduct,
        searchByProductContext,
        setSearchByProductContext,
        searchTermProductContext,
        setSearchTermProductContext,
        isClickedOnCart,
        setIsClickedOnCart,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
}

export default ProductContextProvider;
export { ProductContext };
