import { createContext, useState } from 'react';
const SearchContext = createContext();
function SearchContextProvider({ children }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isFilteringFromContext, setIsFilteringFromContext] = useState(false);

  return (
    <SearchContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
        isFilteringFromContext,
        setIsFilteringFromContext,
      }}
    >
      {children}
    </SearchContext.Provider>
  );
}
export default SearchContextProvider;
export { SearchContext };
