// src/components/TawkToManager.js

import React, { useEffect } from 'react';
import { useContext } from 'react';
import { AuthContext } from '../../context/AuthContext';
import { useLocation } from 'react-router-dom';

// const loadTawkTo = () => {
//   const tawkToScript = document.createElement('script');
//   tawkToScript.async = true;
//   // tawkToScript.src =
//   //   'https://embed.tawk.to/dbf2c46898ea2b20973a246e563a8187064ad050/default'; // Replace with your actual Tawk.to property ID
//   tawkToScript.src = 'https://embed.tawk.to/66e3b06dea492f34bc12e068/1i7kmib69'; // Replace with your actual Tawk.to property ID
//   tawkToScript.charset = 'UTF-8';
//   tawkToScript.setAttribute('crossorigin', '*');
//   document.body.appendChild(tawkToScript);
// };
//----------------------------------------------
//Work before
// const loadTawkTo = () => {
//   const s1 = document.createElement('script'),
//     s0 = document.getElementsByTagName('script')[0];
//   s1.async = true;
//   s1.src = 'https://embed.tawk.to/66e3b06dea492f34bc12e068/1i7kmib69';
//   s1.charset = 'UTF-8';
//   s1.setAttribute('crossorigin', '*');
//   s0.parentNode.insertBefore(s1, s0);
// };
//----------------------------------------------
//----------------------------------------------
const loadTawkTo = () => {
  const tawkToScript = document.createElement('script');
  tawkToScript.async = true;
  tawkToScript.src = 'https://embed.tawk.to/66e3b06dea492f34bc12e068/1i7kmib69'; // Replace with your actual Tawk.to property ID
  tawkToScript.charset = 'UTF-8';
  tawkToScript.setAttribute('crossorigin', '*');
  document.body.appendChild(tawkToScript);
};

const removeTawkTo = () => {
  const tawkToScript = document.querySelector('script[src*="tawk.to"]');

  if (window.Tawk_API && typeof window.Tawk_API.hideWidget === 'function') {
    window.Tawk_API.hideWidget();
  }

  if (tawkToScript) {
    tawkToScript.remove();
  }
};

const TawkToManager = () => {
  let location = useLocation();
  const { role } = useContext(AuthContext);
  useEffect(() => {
    if (
      (role === 'user' || role === null) &&
      location.pathname !== '/chart/panit'
    ) {
      loadTawkTo();
      if (window.Tawk_API && typeof window.Tawk_API.showWidget === 'function') {
        window.Tawk_API.showWidget();
      }
    }
    if (role === 'admin' || role === 'saleAdmin') {
      removeTawkTo();
    }
    if (location.pathname === '/chart/panit') {
      removeTawkTo();
    }
  }, [role]);

  return null; // This component does not render anything
};

export default TawkToManager;
